import React from 'react';
import './style.css';
import {Heading, LogoBanner, Spinner} from '@vwfs-bronson/bronson-react';
import SearchContainerView from '../../components/search-container-view';
import {useAppSelector} from "../../redux/hooks";
import {selectCustomer} from "../../redux/slices/customer-slice";

export default function SearchViewPage() {
  const customer = useAppSelector(selectCustomer());

  return (
    <div className="App">
      <div className="column">
        <div className="c-header__wrapper" data-testid="heading">
          <div data-testid="CustomerSearchLabel" className='c-header  c-header--static'>
            <div className='c-header__content-wrapper'>
              <LogoBanner
                imageProps={{
                  alt: 'Back-Office MobilityApp',
                  src: "https://cdn.bronson.vwfs.tools/bluelabel/v/15.6.0/img/logo.svg",
                }}
                className='banner'
                logoHeight='180px'
              />
              <div className='banner-heading' data-testid="HeaderHeading">
                <Heading level={6} className="display-inline">
                  Back-Office MobilityApp
                </Heading>
              </div>
            </div>
          </div>
        </div>
        <div id="body-content" className="container">
          <div className="search-container">
            <SearchContainerView/>
          </div>
        </div>
      </div>
      <Spinner fullPage busy={customer.status == 'loading'} testId='searchSpinner'/>
    </div>
  );
}
