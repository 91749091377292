import React from 'react';
import {
  CheckboxGroup,
  FormField,
  DatePicker,
  Button
} from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setTripsDateRageFilter,
  selectTrips
} from "../../../redux/slices/customer-slice";
import { FilterByDateRange } from '../../../redux/slices/types';

export default function TripHistoryDataFilter() {
  const dispatch = useAppDispatch();
  const trips = useAppSelector(selectTrips());

  function setDateRange(dateRange: FilterByDateRange) {
    dispatch(setTripsDateRageFilter(dateRange));
  }

  function handleOnDateRangeChange(e) {
    if (e.includes('bis')) {
      const SPLIT_FLAG = ' bis ';
      const dates = e.split(SPLIT_FLAG);
      setDateRange({
        start: dates[0],
        stop: dates[1],
      })
    }
  }

  function handleResetFilters() {
    setDateRange(undefined);
  }

  return (
    <div data-testid="tripsFilter" key="tripsFilter" className="filter-group">
      <CheckboxGroup key="tripsFilterCheckboxGroup">
        <FormField
          labelText="Select Date Range"
          type="input"
          testId='testSelectDateRange'
        >
          <DatePicker
            testId='testDatePicker'
            key="tripsDataRangeSelect"
            mode='range'
            value={trips.filter?.dateRange ? `${trips.filter.dateRange.start} bis ${trips.filter.dateRange.stop}` : undefined}
            onChange={handleOnDateRangeChange} />
        </FormField >
        <FormField
          labelText="Action"
          type="input"
        >
          <Button
            onClick={handleResetFilters}
            key="resetTripFilters"
            testId='testResetFiltersBtn'
          >
            Reset filters
          </Button>
        </FormField >
      </CheckboxGroup>
    </div >
  );
}
