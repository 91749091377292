import React from 'react';
import { FormFieldLabel, Modal, Spinner, Table } from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetErrorDialog, selectBookings, selectCustomer } from "../../../redux/slices/customer-slice";
import {
  BookingAdditionalProduct,
  ICustomerBooking, PrePaymentStateType,
} from "../../../models/customer-data.interface";

export default function BookingHistoryDataView() {
  const customer = useAppSelector(selectCustomer());
  const bookings = useAppSelector(selectBookings());
  const dispatch = useAppDispatch();
  let keys: string[];
  function renderAdditionalProduct(product: BookingAdditionalProduct) {
    const prods: string[] = [];


    product.choices?.map(choice => {
      if ((choice.isSelected || choice.includedInPackage) && !keys.includes(choice.code)) {
        keys.push(choice.code);
        prods.push(choice.name);
      }
    })
    return prods.map(prod => <li key={prod}>{prod}</li>);
  }

  const handleConfirmErrorDialog = () => {
    dispatch(resetErrorDialog(undefined));
  };

  function renderPaymentState(prePaymentState: PrePaymentStateType) {
    return (prePaymentState ? 'PrePayment (' + prePaymentState + ')' : 'PayOnDesk');
  }

  function renderItem(booking: ICustomerBooking) {
    const formatter = new Intl.DateTimeFormat('de-DE',
      { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
    keys = [];
    return (
      <Table.Tr key={booking?.id} className="table-row">
        <Table.Td><img src={booking.offer.vehicleCategory.model.vehicleImages[0].url} height="50px;" /> ({booking.offer.vehicleCategory.name})</Table.Td>
        <Table.Td>{booking?.station.stationName + " (" + booking.station.address + ")"}</Table.Td>
        <Table.Td>{formatter.format(new Date(booking?.offer.startDate))}</Table.Td>
        <Table.Td>{formatter.format(new Date(booking?.offer.endDate))}</Table.Td>
        <Table.Td><ul>{booking.offer.additionalProducts.map(product => renderAdditionalProduct(product))}</ul></Table.Td>
        <Table.Td>{booking?.offer.productCostAmount.grossAmount + " " + booking?.offer.productCostAmount.currency}</Table.Td>
        <Table.Td>{renderPaymentState(booking?.paymentState)}</Table.Td>
      </Table.Tr>
    );
  }
  return (
    <>
      {bookings.data && bookings.data?.length > 0 && (
        <Table colored={true} testId="booking-history-table">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Category</Table.Th>
              <Table.Th>Station</Table.Th>
              <Table.Th>Pickup Date</Table.Th>
              <Table.Th>Return Date</Table.Th>
              <Table.Th>Additional Products</Table.Th>
              <Table.Th>Gross Amount</Table.Th>
              <Table.Th>Payment</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{bookings?.data?.map((booking) => renderItem(booking))}</Table.Tbody>
        </Table>
      )}
      {((!bookings.data || bookings.data?.length === 0) && bookings?.status !== 'failed') && (
        <FormFieldLabel testId="noBookingsLabel">No Bookings for Customer</FormFieldLabel>)}
      {bookings?.status === 'failed' && (
        <FormFieldLabel testId="errorBookingsLabel">{customer.error}</FormFieldLabel>)}
      <Spinner fullPage busy={bookings.status === 'loading'} testId='bookingHistorySearchSpinner' />
      <Modal
        shown={customer?.errorDialog?.code !== undefined}
        onClose={handleConfirmErrorDialog}
        onConfirm={handleConfirmErrorDialog}
        buttonConfirmLabel="Ok"
        buttonConfirmText="Ok"
        title={customer?.errorDialog?.title}
        status='error'
        testId='confirmErrorModal'
      >
        <p>{customer?.errorDialog?.message}</p>
      </Modal>
    </>
  );
}
