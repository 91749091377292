import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const TabIdMap = {
    PersonalData: 'personal',
    IdVerificationData: 'id-verification',
    ConsentData: 'consent',
    PaymentData: 'payment',
    MSPData: 'msp',
    BookingHistory: 'booking-history',
    AuditTrail: 'audit-trail',
    TripHistory: 'trip-history',
}

const TAB_ID_SEARCH_PARAM = "tab"

export const useSelectedTab = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedTabId, setSelectedTabId] = useState(null);

    useEffect(() => {
        const paramsTabId = searchParams.get(TAB_ID_SEARCH_PARAM);
        if (!paramsTabId) {
            setSelectedTabId(TabIdMap.PersonalData)
            return;
        }

        if (!Object.values(TabIdMap).includes(paramsTabId)) {
            return;
        }

        if (selectedTabId !== paramsTabId) {
            setSelectedTabId(paramsTabId)
        }
    }, [searchParams]);

    const onTabClicked = (newTabId) => {
        if (!newTabId) {
            return;
        }

        if (newTabId !== selectedTabId) {
            setSelectedTabId(newTabId)
            setSearchParams({ [TAB_ID_SEARCH_PARAM]: newTabId });
        }
    }

    return {
        selectedTabId,
        onTabClicked
    }
}