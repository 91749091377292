import React from 'react';
import './style.css';
import SearchView from '../search-view';

export default function SearchContainerView() {
    return (
        <div data-testid="searchContainer" className="flex-container">
            <div data-testid="searchComponent" className="flex-child">
                <SearchView />
            </div>
        </div>
    );
}
