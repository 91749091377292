import React from 'react';
import './style.css';
import {FormFieldLabel} from '@vwfs-bronson/bronson-react';

interface PropType {
  id: string;
  label: string;
  value?: string;
}

export default function DetailComponent({
  id,
  label,
  value,
}: PropType) {
  return (
    <div className='details-info'>
      <FormFieldLabel testId={`${id}Label`} className="label">{label}</FormFieldLabel>
      <FormFieldLabel
        testId={`${id}Field`}>{value}</FormFieldLabel>
    </div>
  );
}
