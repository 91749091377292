import React, { useState } from 'react';
import { FormFieldLabel, InfoIcon, Modal, Spinner, Table, Tooltip } from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetErrorDialog, selectCustomer, selectTrips } from "../../../redux/slices/customer-slice";
import {
  ICustomerTrip,
  ICustomerTripBilling,
  ICustomerTripBillingState,
} from "../../../models/customer-data.interface";
import InvoicesModal from '../../base/invoices-modal';
import { Icon } from '@vwfs-bronson/bronson-react/dist/Icon';

const formatter = new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });

export default function TripHistoryDataView() {
  const customer = useAppSelector(selectCustomer());
  const trips = useAppSelector(selectTrips());
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const handleOnClose = () => {
    setShowModal(false);
    setInvoices([]);
  }
  const handleOnModalShow = (invoices) => {
    setInvoices(invoices);
    setShowModal(true);
  }

  const handleConfirmErrorDialog = () => {
    dispatch(resetErrorDialog(undefined));
  };


  function renderStatusIcon(state: ICustomerTripBillingState) {
    return <Tooltip content={state} placement='top'>
      <span>
        {
          state === 'REJECTED' || state === 'CANCELED' ?
            <Icon className="c-badge__icon c-icon--[semantic-close] semantic-close c-icon--xsmall" /> :
            <Icon className="c-badge__icon c-icon--[semantic-checkmark] semantic-checkmark c-icon--xsmall" />
        }
      </span>
    </Tooltip>
  }

  function renderBillingInvoices(billings: ICustomerTripBilling[]) {
    const billingMap = billings.reduce((map, billing) => {
      if (!map[billing.invoiceNumber]) {
        map[billing.invoiceNumber] = [billing];
      } else {
        map[billing.invoiceNumber].push(billing);
      }

      return map;
    }, {} as { [invoiceNumber: string]: ICustomerTripBilling[] })

    const invoiceNumbers = Object.keys(billingMap);

    return (
      <ul className='row-ul'>
        {
          invoiceNumbers.map(invoiceNumber => {
            const items = billingMap[invoiceNumber];

            if (items.length === 1) {
              const item = items[0];
              return <li key={item.invoiceNumber} className='row-li'>
                <span key={item.invoiceNumber} className='row-li-inline'>
                  <a onClick={() => handleOnModalShow([item])}>
                    {`${item.type}`}
                  </a>
                </span>
                <span className='row-li-inline'>
                  {
                    renderStatusIcon(item.state)
                  }
                </span>
              </li>
            }

            const sortedItems = items.sort(i => i.type !== 'REFUND' ? 0 : 1);
            const isCanceled = sortedItems.find(item => item.state === 'REJECTED' || item.state === 'CANCELED');

            return <>
              {sortedItems.map(item =>
                <li key={`${item.invoiceNumber}-${item.type}`} className='row-li-inline'>
                  <a onClick={() => handleOnModalShow(sortedItems)}>
                    {item.type === 'REFUND' ? '(Refund)' : item.type}
                  </a>
                </li >
              )}

              <li key={`${invoiceNumber}-state`} className='row-li-inline'>
                {
                  isCanceled ? renderStatusIcon('CANCELED') : renderStatusIcon('COMPLETED')
                }
              </li>
            </>
          })
        }
      </ul >
    )
  }

  function renderItem(trip: ICustomerTrip) {
    if (!trip) {
      return null;
    }

    return (
      <Table.Tr key={trip.id} className="table-row">
        <Table.Td>{trip.id}</Table.Td>
        <Table.Td>{trip.state}</Table.Td>
        <Table.Td>{trip.travelledMileage.value} {trip.travelledMileage.unit}</Table.Td>
        <Table.Td>{trip.vehicle.vehicle.model.vehicleType}</Table.Td>
        <Table.Td>{trip.tripPeriod ? formatter.format(new Date(trip.tripPeriod.startTimestamp)) : '-'}</Table.Td>
        <Table.Td>{trip.billings.length > 0 ? renderBillingInvoices(trip.billings) : '-'}</Table.Td>
      </Table.Tr>
    );
  }

  return (
    <>
      {trips.data && trips.data?.length > 0 && (
        <Table colored={true} testId="trip-history-table">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Trip Id</Table.Th>
              <Table.Th>State</Table.Th>
              <Table.Th>Distance</Table.Th>
              <Table.Th>Vehicle type</Table.Th>
              <Table.Th>Start Time</Table.Th>
              <Table.Th>Invoices<InfoIcon className="semantic-info" testId="infoIconInvoicesData">Click on invoice for more details</InfoIcon></Table.Th>
            </Table.Tr >
          </Table.Thead >
          <Table.Tbody>{trips?.data?.map((trip) => renderItem(trip))}</Table.Tbody>
        </Table >
      )
      }

      <InvoicesModal invoices={[...invoices]} showModal={showModal} handleOnClose={handleOnClose} />

      {
        ((!trips.data || trips.data?.length === 0) && trips?.status !== 'failed') && (
          <FormFieldLabel testId="noTripsLabel">No Trips for Customer</FormFieldLabel>)
      }

      {
        trips?.status === 'failed' && (
          <FormFieldLabel testId="errorTripsLabel">{customer.error}</FormFieldLabel>)
      }

      <Spinner fullPage busy={trips.status === 'loading'} testId='tripHistorySearchSpinner' />
      <Modal
        shown={customer?.errorDialog?.code !== undefined}
        onClose={handleConfirmErrorDialog}
        onConfirm={handleConfirmErrorDialog}
        buttonConfirmLabel="Ok"
        buttonConfirmText="Ok"
        title={customer?.errorDialog?.title}
        status='error'
        testId='confirmErrorModal'
      >
        <p>{customer?.errorDialog?.message}</p>
      </Modal>
    </>
  );
}
