import React from 'react';
import { FormFieldLabel, Table} from '@vwfs-bronson/bronson-react';
import './style.css';
import {useAppSelector} from "../../../redux/hooks";
import {selectCustomer} from "../../../redux/slices/customer-slice";
import {
  ConsentData,
} from "../../../models/customer-data.interface";


export default function ConsentDataView() {
  const customer = useAppSelector(selectCustomer());

  function renderItem(cd: ConsentData) {
    return (
      <Table.Tr key={cd.consentType} className='table-row'>
        <Table.Td>{cd.consentType}</Table.Td>
        <Table.Td>{cd.isGiven.toString()}</Table.Td>
        <Table.Td>{cd.version}</Table.Td>
        <Table.Td>{cd.date}</Table.Td>
      </Table.Tr>
    );
  }

  return (
    <>
      <div data-testid="tableComponent">
        {customer.customer?.consentData && customer.customer?.consentData?.length > 0 && (
          <Table colored={true} >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Type</Table.Th>
                <Table.Th>Is Given</Table.Th>
                <Table.Th>Version</Table.Th>
                <Table.Th>Date</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{customer.customer?.consentData?.map((cd) => renderItem(cd))}</Table.Tbody>
          </Table>
        )}

        {(!customer.customer?.consentData || customer.customer?.consentData?.length === 0) && (<FormFieldLabel testId="noConsentDataLabel">No Consent Data</FormFieldLabel>)}
      </div>
    </>
  );
}
