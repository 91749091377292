import React from 'react';
import { FormFieldLabel, Table} from '@vwfs-bronson/bronson-react';
import './style.css';
import {useAppSelector} from "../../../redux/hooks";
import {selectCustomer} from "../../../redux/slices/customer-slice";
import {
  ConsentData, MspStatus,
} from "../../../models/customer-data.interface";


export default function MspDataView() {
  const customer = useAppSelector(selectCustomer());

  function renderItem(cd: MspStatus) {
    return (
      <Table.Tr key={cd.serviceProviderId} className='table-row'>
        <Table.Td>{cd.serviceProviderId}</Table.Td>
        <Table.Td>{cd.status}</Table.Td>
      </Table.Tr>
    );
  }

  return (
    <>
      <div data-testid="tableComponent">
        {customer.customer?.mspStatus && customer.customer?.mspStatus?.length > 0 && (
          <Table colored={true} >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Status</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{customer.customer?.mspStatus?.map((msp) => renderItem(msp))}</Table.Tbody>
          </Table>
        )}

        {(!customer.customer?.mspStatus || customer.customer?.mspStatus?.length === 0) && (<FormFieldLabel testId="noConsentDataLabel">No MSP Data</FormFieldLabel>)}
      </div>
    </>
  );
}
