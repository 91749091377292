import { combineReducers, configureStore } from '@reduxjs/toolkit';
import customerReducer from './slices/customer-slice';

const rootReducer = combineReducers({
  customer: customerReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export function initializeStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];
export type AppStore = ReturnType<typeof initializeStore>;