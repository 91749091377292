import React, { ElementType } from 'react'
import classNames from 'classnames'
import {BronsonReact} from "@vwfs-bronson/bronson-react/dist/types";
import {IconName} from "@vwfs-bronson/bronson-react/dist/Icon";
import {TooltipProps} from "@vwfs-bronson/bronson-react/dist/Tooltip";
import {Tooltip} from "@vwfs-bronson/bronson-react";

export type InfoIconProps = BronsonReact.Props<{
  /** The {@link Tooltip} content. */
  children?: React.ReactNode
  /** Additional class on the main component element. */
  className?: string
  /** The {@link Tooltip} trigger element. Defaults to 'button'. */
  element?: string
  /** Custom {@link ImageIcon} icon. */
  icon?: IconName | string
  /** Additional {@link TooltipProps}. */
  tippyProps?: Partial<TooltipProps>
}>

/*
 * Bronson InfoIcon component.
 */
export function ImageIcon({
                           children,
                           className,
                           element = 'button',
                           icon,
                           tippyProps,
                           testId,
                           ...otherProps
                         }: InfoIconProps) {
  const CustomElement = `${element}` as ElementType

  const buttonClassNameList = classNames(
    'c-icon',
    {
      [`c-icon--[${icon}]`]: icon,
    },
    className
  ).trim()

  const typeProp = element === 'button' ? { type: 'button' } : {}

  return (
    <Tooltip content={children} {...tippyProps}>
      <CustomElement
        {...otherProps}
        data-testid={testId}
        {...typeProp}
        className={buttonClassNameList}
        data-tippy-content={children} // applies condition
        data-tippy-placement={children && 'top'}
      />
    </Tooltip>
  )
}
