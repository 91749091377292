import ICustomerData, {Address, ConsentData, MspStatus, MspStatusList} from "../models/customer-data.interface";

export function isPersonalDataEqual(data1: ICustomerData, data2:ICustomerData):boolean {
  return data1?.firstName === data2?.firstName &&
    data1?.lastName === data2?.lastName &&
    data1?.language === data2?.language &&
    data1?.dateOfBirth === data2?.dateOfBirth &&
    data1?.email === data2?.email &&
    data1?.phoneNumber === data2?.phoneNumber;
}

export function isAddressDataEqual(data1: Address, data2:Address):boolean {
  return data1?.street === data2?.street &&
    data1?.houseNumber === data2?.houseNumber  &&
    data1?.zipCode === data2?.zipCode  &&
    data1?.city === data2?.city &&
    data1?.countryCode === data2?.countryCode;
}

export function containsMsp(data: MspStatus, dataList: MspStatusList) {
  if (!dataList) {
    return false;
  }
  for (let i = 0; i < dataList?.length; i++){
    if (dataList[i]?.status === data.status && dataList[i]?.serviceProviderId === data.serviceProviderId){
      return true
    }
  }
  return false;
}

export function isMspDataEqual(data1: MspStatusList, data2: MspStatusList){
  if (data1?.length !== data2?.length) {
    return false;
  }

  for (let i = 0; i < data1?.length; i++){
    if (!containsMsp(data1[i], data2)){
      return false;
    }
  }
  return true
}

export function containsConsent(data: ConsentData, dataList: ConsentData[]) {
  if (!dataList) {
    return false;
  }
  for (let i = 0; i < dataList?.length; i++){
    if (dataList[i]?.date === data.date &&
      dataList[i]?.version === data.version &&
      dataList[i]?.isGiven === data.isGiven &&
      dataList[i]?.consentType === data.consentType &&
      dataList[i]?.description === data.description){
      return true
    }
  }
  return false;
}

export function isConsentDataEqual(data1: ConsentData[], data2: ConsentData[]){
  const givenConsentData1 = data1?.filter(it => it.isGiven);
  if (givenConsentData1?.length !== data2?.filter(it => it.isGiven)?.length) {
    return false;
  }

  for (let i = 0; i < givenConsentData1?.length; i++){
    if (!containsConsent(givenConsentData1[i], data2)){
      return false;
    }
  }
  return true
}
