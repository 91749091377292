import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';
import React from 'react';
import {Spinner} from '@vwfs-bronson/bronson-react';

export function ProtectedPageParent() {
    const auth = useAuth();

    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
        auth.signinRedirect();
        return <Spinner fullPage/>
    }

    if (!auth.isAuthenticated) {
        return <Spinner fullPage/>
    }

    return <Outlet />;
}
