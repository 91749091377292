import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ICustomerTrip, ICustomerTripsResponse } from '../../models/customer-data.interface';
import { RootState } from '../store';
import * as customerSupportApi from '../../apis/customer-support-api';
import { CustomerState } from './customer-state';
import { Action } from '../types';
import { FilterByDateRange } from './types';

export const selectTrips = () => (state: RootState) => {
    return state.customer.trips;
};

const thunkHandler = async ({ id }) => {
    const result: ICustomerTripsResponse = await customerSupportApi.getCustomerTrips(id);
    return result.data.sort((e1, e2) => {
        return new Date(e2.tripPeriod?.startTimestamp || 0).valueOf() - new Date(e1.tripPeriod?.startTimestamp || 0).valueOf()
    });
}

const thunkOptions = {
    condition: (refresh, { getState }) => {
        // execute the function if it's explicitly requested
        if (refresh) {
            return true;
        }

        const { customer } = getState();
        customer.error = null;
        return customer.trips.status === 'idle'; // execute the fetch function only when the status is idle
    },
}

export const fetchCustomerTrips = createAsyncThunk<ICustomerTrip[], { id: string }, { state: RootState }>('customer/fetchCustomerTrips', thunkHandler, thunkOptions);

export function addTripsCases(builder: ActionReducerMapBuilder<CustomerState>) {
    builder
        .addCase(fetchCustomerTrips.pending, (state, action) => {
            state.trips.status = 'loading';
            state.error = null;
            state.errorDialog = null;
        })
        .addCase(fetchCustomerTrips.fulfilled, (state, action) => {
            state.error = null;
            state.errorDialog = null;
            state.trips.data = action.payload;
            state.trips.dataSource = action.payload;
            state.trips.status = 'succeeded';
        })
        .addCase(fetchCustomerTrips.rejected, (state, action) => {
            state.error = 'An error occurred: Could not fetch the trips of the customer.';
            state.errorDialog = {
                code: 'UNABLE_TO_FETCH_TRIPS',
                title: 'Unable to fetch Trips',
                message: 'An error occurred: Could not fetch the trips of the customer.'
            }
            state.id = undefined;
            state.trips.status = 'failed';
        })
}

export const tripsReducers = {
    setTripsDateRageFilter(state: CustomerState, action: Action<FilterByDateRange>) {
        const dateRange = action.payload
        state.trips.filter.dateRange = dateRange;

        if (!dateRange) {
            state.trips.data = state.trips.dataSource;
            return
        }

        const startTimestamp = new Date(dateRange.start).getTime();
        const stopTimestamp = new Date(dateRange.stop).getTime();

        const filteredTrips = state.trips.dataSource.filter(item =>
            item.tripPeriod && (
                new Date(item.tripPeriod.startTimestamp).getTime() >= startTimestamp &&
                new Date(item.tripPeriod.endTimestamp).getTime() <= stopTimestamp
            ))


        state.trips.data = filteredTrips;
    },
}