import React from 'react';
import {
  Checkbox,
  CheckboxGroup,
  FormField,
} from '@vwfs-bronson/bronson-react';
import './style.css';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
  selectAuditTrail,
  setAuditTrailFilterCustomerValue,
  setAuditTrailFilterSupportValue
} from "../../../redux/slices/customer-slice";


export default function AuditTrailDataFilter() {
  const dispatch = useAppDispatch();
  const auditTrail = useAppSelector(selectAuditTrail());

  function updateCustomer(checked: boolean) {
    dispatch(setAuditTrailFilterCustomerValue(checked));
  }
  function updateSupport(checked: boolean) {
    dispatch(setAuditTrailFilterSupportValue(checked));
  }
  return (
    <div data-testid="auditTrailFilter" key="auditTrailFilter" className="filter-group">
      <FormField
        labelForId="test-form-input"
        labelText="Changed By"
        type="input"
        testId='testInputText'
      >
      <CheckboxGroup key="auditTrailFilterCheckboxGroup">
        <Checkbox key="auditTrailFilterCheckboxCustomer"
          checked={auditTrail?.filter?.auditChangedBy?.customer}
          testId='auditTrailFilterCheckboxCustomer'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateCustomer(e.target.checked);
          }}>
          Customer
        </Checkbox>
        <Checkbox key="auditTrailFilterCheckboxSupport"
          checked={auditTrail?.filter?.auditChangedBy?.support}
          testId="auditTrailFilterCheckboxSupport"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateSupport(e.target.checked);
          }}>
          Support
        </Checkbox>
      </CheckboxGroup>
      </FormField>
    </div>
  );
}
