import { User } from 'oidc-client-ts';
import AuthConfig from '../../configuration/auth-config';
import ICustomerData, {
  ICustomerAudit,
  ICustomerBookingResponse,
  ICustomerTripsResponse
} from "../../models/customer-data.interface";

function getAccessToken(): string {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${AuthConfig.authority}:${AuthConfig.client_id}`)
  if (!oidcStorage) {
    return 'No token';
  }

  return User.fromStorageString(oidcStorage)?.access_token;
}

export async function getCustomerByEmail(email: string): Promise<ICustomerData> {
  const encodedEmail = encodeURIComponent(email);
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers?email=${encodedEmail}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }
  );

  if (response.status === 403) {
    throw new Error('FORBIDDEN')
  }

  const responseBody = await response.json();
  return responseBody.data;
}

export async function getCustomerById(id: string): Promise<ICustomerData> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    }
  }
  );
  const responseBody = await response.json();
  return responseBody.data;
}
export async function getCustomerAuditTrail(id: string, pageSize: number, cursor?: string): Promise<ICustomerAudit> {
  const cursorParam = cursor ? '&cursor=' + cursor : '';
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/audit-trail?pageSize=${pageSize}${cursorParam}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }
  );
  return await response.json();
}

export async function markCustomerForDeletion(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/mark-for-deletion`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
    method: "POST"
  },
  );
  if (response?.status === 204) {
    return await response.text();
  } else {
    return (await response.json())?.errors[0]?.code as string | 'UNKNOWN_ERROR';
  }
}

export async function unmarkCustomerForDeletion(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/unmark-for-deletion`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
    method: "POST"
  },
  );
  return await response.text();
}

export async function resetIdVerification(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/reset-id-verification`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
    method: "POST"
  },
  );
  return await response.text();
}

export async function suspendCustomer(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/suspend`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
    method: "POST"
  },
  );
  if (response.status === 204) {
    return await response.text();
  } else {
    const json = await response.json();
    return json.errors[0].code as string | 'UNKNOWN_ERROR';
  }
}

export async function unsuspendCustomer(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/unsuspend`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
    method: "POST"
  },
  );
  return await response.text();
}

export async function getCustomerBookings(id: string): Promise<ICustomerBookingResponse> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/bookings`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    }
  }
  );
  return await response.json();
}

export async function getCustomerTrips(id: string): Promise<ICustomerTripsResponse> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/trips`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    }
  }
  );
  return await response.json();
}
