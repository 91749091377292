import { clearAuthParams } from '../utils/auth-utils';

export default {
    authority: 'https://id.platform.vwfs.io/auth/realms/digitalunit/protocol/openid-connect/auth',
    metadataUrl: 'https://id.platform.vwfs.io/auth/realms/digitalunit/.well-known/openid-configuration',
    client_id: 'vwfs-du-mobilityapp-backoffice',
    redirect_uri: window.location.origin ,
    response_type: 'code',
    onSigninCallback: clearAuthParams,
};
