import React from 'react';
import ReactDOM from 'react-dom/client';
import "@vwfs-bronson/bronson-bluelabel/dist/css/style.min.css";
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "react-oidc-context";
import AuthConfig from "./configuration/auth-config";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ProtectedPageParent} from "./pages/ProtectedPageParent";
import SearchViewPage from "./pages/SearchViewPage";
import DetailViewPage from "./pages/DetailViewPage";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <AuthProvider {...AuthConfig}>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<ProtectedPageParent/>}>
                      <Route path='/' element={ <SearchViewPage /> }/>
                      <Route path='/customers/:customerPathId' element={ <DetailViewPage /> } />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
